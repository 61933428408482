
import './App.css';

function App() 
{


  function clickPhone()
  {
    var telLink = 'tel:33675655811';
    window.location.href = telLink;
  }


  return (
    <div className="App absolute t-0 w-full h-screen bg-black text-white overflow-hidden">

      <div className="video-background">
        <video autoPlay muted loop>
            <source src="videos/5080096_Hacker_Gamer_1280x720.mp4" type="video/mp4" />
            Votre navigateur ne prend pas en charge les vidéos.
        </video>
      </div>

 

      <div className='absolute w-[100%] h-[100%] flex justify-center overflow-auto'>

        <div className='absolute lg:h-auto lg:w-auto sm:w-[100%] sm:h-[100%] p-20 sm:p-5 self-auto bg-gray-900 bg-opacity-30 hover:bg-opacity-60 transition ease-in-out duration-700 rounded-2xl max-w-screen-2xl'>  

          <h1 className='sm:text-2xl text-4xl font-light mb-5 text-nowrap'>Grégory Lardon<br/>Développeur Freelance</h1>

                    
          <div className='profile-container'>
            <img src="images/gregory-lardon-developpeur-freelance.jpg" className="profile-photo" alt="Grégory Lardon - développeur freelance - Profile Photo"/>
          </div>

          <p className='mt-5 pb-5 text-lg font-mono'>20 ans+ d'expérience passionnée dans le développement logiciel</p>

          <p className='mt-10 text-xl font-mono'>Web | Desktop | Mobile</p>
          <p className='mt-6 text-lg font-mono'>Développement front-end créatif</p>
          <p className='mt-3 text-lg font-mono'>Solutions interactives pour événements professionnels</p>
          <p className='mt-3 text-lg font-mono'>Développement d'expériences immersives pour la formation professionnelle</p>
          <p className='mt-3 text-lg font-mono'>E-Learning | Jeux vidéos | Serious Gaming</p>
          <p className='mt-3 text-lg font-mono'>Réalité virtuelle | Réalité augmentée</p>
          <p className='mt-3 text-lg font-mono'>Configurateurs 3D | Cartographie interactive | etc.</p>

          <div className='m-10 mb-20 mt-20 flex flex-row flex-wrap text-xl items-center justify-center gap-10 sm:gap-0'>

            <div className='rounded-md border-2 transition ease-out duration-300 p-5 border-transparent hover:border-slate-600'>
              <h2 className='mx-10 text-2xl font-semibold text-nowrap bg-sky-900 p-5 rounded-md'>Unity 3D</h2>
              <div className='mt-5 font-mono'>
                <div>Desktop / Mobile</div>
                <div>AR / VR</div>
                <div>Editor Tools</div>
              </div>
            </div>
            <div className='rounded-md border-2 transition ease-out duration-300 p-5 border-transparent hover:border-slate-600'>
              <h2 className='mx-10 text-2xl font-semibold text-nowrap bg-sky-900 p-5 rounded-md'>Web Fullstack</h2>
              <div className='mt-5 font-mono'>
                <div>React / Tailwind css</div>
                <div>Node.js</div>
                <div>Next.js / Prisma</div>
              </div>
            </div>
            <div className='rounded-md border-2 transition ease-out duration-300 p-5 border-transparent hover:border-slate-600'>
              <h2 className='mx-10 text-2xl font-semibold text-nowrap bg-sky-900 p-5 rounded-md'>WebGL</h2>
              <div className='mt-5 font-mono'>
                <div>ThreeJS</div>
                <div>BabylonJS</div>
                <div>WebXR</div>
              </div>
            </div>           
            
          </div>


          <p className='text-lg font-mono mb-5'>Toujours à la recherche de nouveaux projets passionnants.</p>
          <h2 className='text-2xl font-semibold text-nowrap pb-10'>Prenons contact !</h2>

          <div className='flex items-center justify-center gap-5'>
            <a href='https://www.malt.fr/profile/gregorylardon' target='_blank'>
              <button className='w-32 p-5 bg-sky-800 rounded-md hover:bg-sky-900 transition ease-in-out duration-300'>
                <img src="images/malt-logo-red.svg" />
              </button>
            </a>
            <a href='https://www.linkedin.com/in/developpeur-freelance-unity-javascript-threejs-react/' target='_blank'>
              <button className='w-32 p-5 bg-sky-800 rounded-md hover:bg-sky-900 transition ease-in-out duration-300'>
                <img src="images/LinkedIn_Logo.svg" />
              </button>
            </a>
          </div>



          <div className='flex items-center justify-center gap-5 mt-5'>
            <a href='mailto:gregory.lardon@gmail.com' target='_blank'>
              <button className='p-5 bg-sky-800 rounded-md hover:bg-sky-900 transition ease-in-out duration-300'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>
              </button>
            </a>
           
              <button onClick={clickPhone} className='p-5 bg-sky-800 rounded-md hover:bg-sky-900 transition ease-in-out duration-300'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                  <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                </svg>
              </button>
            
          </div>

          <div className='sm:h-10 h-0'></div>


        </div>

      </div>


    </div>
  );
}

export default App;
